import React, { PureComponent } from "react";
import { Redirect, Link } from "react-router-dom";
import { withRouter } from "react-router";
import {
  Button,
  Row,
  Col,
  Input,
  Layout,
  Form,
  Alert,
  Modal,
  message,
  Switch,
  Tabs,
  notification,
  Spin,
  Pagination,
  Tag,
  Breadcrumb,
  Card,
  Space,
  Table,
  Popconfirm,
  Skeleton,
  Select,
  InputNumber,
  Radio,
  Checkbox,
  DatePicker,
} from "antd";
import {
  TeamOutlined,
  SearchOutlined,
  PlusOutlined,
  FormOutlined,
  CloseSquareOutlined,
  ExclamationCircleOutlined,
  DiffOutlined,
  RedoOutlined,
  EditOutlined,
  DeleteOutlined,
  DownloadOutlined,
  SaveOutlined,
  BookOutlined,
  CopyOutlined
} from "@ant-design/icons";
// import "@ant-design/compatible/assets/index.css";
import Config from "../../Config";
import { Helmet } from "react-helmet";

import { Editor } from "@tinymce/tinymce-react";

import { connect } from "react-redux";
import { setConfig, ajaxHandler, ajaxViewHandler } from "../../store/actions";

import moment from "moment";

import axios from "axios";
import Cookies from "js-cookie";

class ReviewSupervisi extends PureComponent {
  config;
  timeoutGetQr;
  timeOutPushQr;
  formRef = React.createRef();
  formRefModal = React.createRef();
  focusComponent = "";
  pk = "";

  constructor(props) {
    super(props);
    this.config = new Config();
    this.state = {
      openDetailReview: false,
      jenisReview: "approval_satu",
      openReview: false,
      catatan: "",
      loadingEditor: true,
      suratTugas: null,
      errTitle: "",
      errMsg: "",
      loadingData: false,
      pagination: { pageSize: 10, current: 1, total: 0 },
      searchText: "",
      dataSource: [],
      prevSource: [],
      columns: [
        {
          title: "Nomor Surat",
          width: 150,
          dataIndex: "surat_tugas_nomor",
          key: "surat_tugas_nomor",
        },
        {
          title: "Nama",
          width: 150,
          dataIndex: "nama",
          key: "nama",
        },
        {
          title: "Masalah",
          width: 150,
          dataIndex: "masalah",
          key: "masalah",
          render: (value, row, index) => {
            return (
              value.map((item, idx) => (
                <>
                  <Tag color="default">
                    halaman: {item.halaman}<br />
                    masalah: {item.masalah}<br />
                    penyelesaian: {item.masalah}
                  </Tag>
                  <br />
                </>
              ))
            )
          }
        },
        {
          title: "Tanggal Pembuatan",
          width: 150,
          dataIndex: "created_at",
          key: "created_at",
          align: "center",
          render: (value, row, index) => {
            return value != null ? moment(value).format("DD-MM-YYYY") : "-";
          },
        },
        {
          title: "Aksi",
          width: 150,
          fixed: "right",
          dataIndex: "id",
          key: "aksi",
          align: "center",
          render: (value, row, index) => {
            return (
              <Space>
                <Link to={"review-sheet-supervisi/view/" + value}>
                  <Button
                    size="small"
                    title="review"
                    type="primary"
                    ghost
                    success
                    htmlType="button"
                  >
                    <CopyOutlined />
                  </Button>
                </Link>
                <Link to={"review-sheet-supervisi/edit/" + value}>
                  <Button
                    size="small"
                    title="review"
                    type="primary"
                    ghost
                    default
                    htmlType="button"
                  >
                    <EditOutlined />
                  </Button>
                </Link>
                <Button
                  size="small"
                  title="review"
                  type="primary"
                  ghost
                  danger
                  htmlType="button"
                >
                  <DeleteOutlined />
                </Button>
              </Space>
            )
          }
        }
      ],
      tahunDipilih: new Date().getFullYear(),
      tambah: false,
      aksi: "tambah",
      method: "post",
      idLama: "",
      editingKey: "",
      open: false,
      filterJenis: 1,

      anggota: [],
      hariAnggota: [],

      supervisi: [
        {
          tipe: 1,
          tanggal_rencana: null,
          tanggal_realisasi: null,
          anggota: false,
        },
      ],

      realisasi: [
        {
          tipe: 2,
          tanggal_rencana: null,
          tanggal_realisasi: null,
          anggota: false,
        },
      ],

      rencana_kunjungan: [],
    };
  }

  async getMasterList(stateName, url, isNext = false) {
    this.setState({
      ["loading" + stateName]: !isNext,
      ["loadingNext" + stateName]: isNext,
      ["loadingSearch" + stateName]: isNext,
    });
    this.props
      .ajaxViewHandler("get", url)
      .then(() => {
        const data = this.props.responseMsg;
        if (this.props.isSuccess) {
          if (data.count != undefined) {
            let newState = null;
            if (
              this.state["data" + stateName] == undefined ||
              this.state["data" + stateName] == null
            ) {
              newState = data;
            } else {
              if (data.previous == null) {
                //first data
                newState = data;
              } else {
                const oldState = this.state["data" + stateName];
                newState = {
                  count: data.count,
                  next: data.next,
                  previous: data.previous,
                  results: [...oldState.results, ...data.results],
                };
              }
            }
            // console.log('state_name', destination.stateDestination);
            // console.log('state_value', newState);
            this.setState(
              {
                ["data" + stateName]: newState,
              },
              () => {
                if (stateName == "jenis_surat") {
                  let temp = [];
                  data.results.map((item, idx) => {
                    temp = [
                      ...temp,
                      {
                        value: item.id,
                        label: item.nama,
                      },
                    ];
                  });
                  this.setState({
                    ["data" + stateName]: temp,
                  });
                }
              }
            );
          } else if (data.results != undefined) {
            this.setState({
              ["data" + stateName]: data.results,
            });
          } else {
            if (Array.isArray(data)) {
              this.setState({
                ["data" + stateName]: data,
              });
            } else {
              this.setState({
                ["data" + stateName]: [data],
              });
            }
          }
        } else {
          console.log(data);
        }
        this.setState({
          ["loading" + stateName]: false,
          ["loadingNext" + stateName]: false,
          ["loadingSearch" + stateName]: false,
        });
      })
      .catch((response) => {
        console.log(response);
        this.setState({
          ["loading" + stateName]: false,
          ["loadingNext" + stateName]: false,
          ["loadingSearch" + stateName]: false,
        });
      });
  }

  generateYears = () => {
    var start = 2020;
    var end = new Date().getFullYear() + 3;
    var items = [];
    for (var i = start; i <= end; i++) {
      items = [
        ...items,
        {
          value: i,
          label: i,
        },
      ];
    }
    this.setState(
      {
        yearItems: items,
      },
      () => {
        // console.log('year', this.state.yearItems)
        this.formRef.current.setFieldsValue({
          tahun: new Date().getFullYear(),
        });
      }
    );
  };

  generateSelectAsync = (stateName, url, label, value, idxAnggota = -1) => {
    return this.state["loading" + stateName] ? (
      <Skeleton active paragraph={false} />
    ) : (
      <Select
        style={{ minWidth: 200 }}
        dropdownMatchSelectWidth={true}
        id={stateName}
        showSearch
        placeholder="pilih"
        optionFilterProp="children"
        allowClear
        onSearch={(val) => {
          let tempUrl = "";
          if (url.includes("?")) {
            tempUrl = url + "&search=" + val;
          } else {
            tempUrl = url + "?search=" + val;
          }
          this.getMasterList(stateName, tempUrl, false);
        }}
        onPopupScroll={(e) => {
          e.persist();
          let target = e.target;
          if (target.scrollTop + target.offsetHeight >= target.scrollHeight) {
            if (
              this.state["data" + stateName].next != undefined &&
              this.state["data" + stateName].next != null
            ) {
              this.getMasterList(
                stateName,
                this.state["data" + stateName].next,
                true
              );
            }
          }
        }}
        onChange={(val) => {
          if (stateName == "pegawai" || stateName == "jabatan_tugas") {
            let temp = [...this.state.anggota];
            temp[idxAnggota][stateName] = val;
            this.setState({
              anggota: temp,
            });
          }
        }}
      >
        {this.state["loadingSearch" + stateName] && (
          <Skeleton active paragraph={false} />
        )}
        {this.state["data" + stateName] != undefined &&
          this.state["data" + stateName] != null && (
            <>
              {this.state["data" + stateName].results != undefined
                ? this.state["data" + stateName].results.map((item) => (
                  <Select.Option value={item[value]}>
                    {item[label]}
                  </Select.Option>
                ))
                : this.state["data" + stateName].map((item) => (
                  <Select.Option value={item[value]}>
                    {item[label]}
                  </Select.Option>
                ))}
            </>
          )}
        {this.state["loadingNext" + stateName] && (
          <Skeleton active paragraph={false} />
        )}
      </Select>
    );
  };

  setFile(e, name, id) {
    this.setState(
      {
        [name + "_file"]: e.target.files[0],
      },
      () => {
        // console.log(this.state[name+"_file"])
        let params = new FormData();
        params.append("lampiran", this.state[name + "_file"], this.state[name + "_file"].name)
        this.doUploadLampiran(params, id);
      }
    );
  }

  showReview(row) {
    this.setState(
      {
        suratTugas: row,
        openReview: true,
      },
      () => {
      }
    );
  }

  showInsert(row) {
    let tempAnggota = [];
    if (row.anggota != undefined) {
      row.anggota.map((item, idx) => {
        tempAnggota = [...tempAnggota, {
          label: item.pegawai_nama == undefined ? "anggota->pegawai_nama" : item.pegawai_nama,
          value: item.pegawai,
        }];
      });
    }
    this.setState(
      {
        suratTugas: row,
        open: true,
        aksi: "tambah",
        idLama: "",
        method: "post",
        editingKey: "",
        anggota: tempAnggota,
        hariAnggota: row.anggota,
      },
      () => {
        this.formRefModal.current.resetFields();
        this.formRefModal.current.setFieldsValue({
          nomor_surat: row.surat_tugas_nomor
        });
      }
    );
  }

  showEdit = (record) => {
    this.formRef.current.resetFields();

    let tempData = this.state.dataSource;

    this.setState(
      {
        prevSource: tempData,
        editingKey: record.id,
      },
      () => {
        this.preview(record.id);
      }
    );
    // this.formRef.current.setFieldsValue({
    //   nama: '',
    //   ...record,
    // });
    // this.setState({
    //   editingKey: record.id
    // }, console.log('editingkey', record.id));
  };

  async preview(id) {
    this.setState({
      loadingData: true,
      errTitle: "",
      errMsg: "",
    });

    this.props
      .ajaxViewHandler("get", this.props.configClass.apiUrl.preview + id)
      .then(() => {
        // setTimeout(() => {
        const data = this.props.responseMsg;
        if (this.props.isSuccess) {
          // this.formRef.current.setFieldsValue(data);
          for (var key in data) {
            const dateRegex = /^[0-9]{4}\-[0-9]{2}\-[0-9]{2}$/;
            const dateTimeRegex =
              /^[0-9]{4}\-[0-9]{2}\-[0-9]{2} [0-9]{2}\:[0-9]{2}\:[0-9]{2}$/;
            if (dateRegex.test(data[key])) {
              //jika format tanggal
              this.formRef.current.setFieldsValue({
                [key + "_picker"]: moment(new Date(data[key]), "DD-MM-YYYY"),
                [key]: moment(new Date(data[key]), "DD-MM-YYYY"),
              });
            } else if (dateTimeRegex.test(data[key])) {
              //jika format tanggal dan jam
              this.formRef.current.setFieldsValue({
                [key + "_picker"]: moment(
                  new Date(data[key]),
                  "DD-MM-YYYY HH:mm:ss"
                ),
                [key]: moment(new Date(data[key]), "DD-MM-YYYY HH:mm:ss"),
              });
            } else {
              if (key == "body") {
                this.setState({
                  artikelString: data[key],
                });
                this.formRef.current.setFieldsValue({
                  content_editor: data[key],
                  body_editor: data[key],
                });
              } else if (key == "tag") {
                let temp = [];
                data[key].map((item) => {
                  temp = [
                    ...temp,
                    {
                      value: item.id,
                      label: item.nama,
                      key: item.id,
                    },
                  ];
                });
                this.formRef.current.setFieldsValue({
                  tags: temp,
                });
              } else if (key == "image" || key == "dokumen") {
                // let temp = [];
                // let tempGb = [];
                // data[key].map((item) => {
                //   temp = [
                //     ...temp,
                //     {
                //       id: item.id,
                //       name: item.name,
                //     },
                //   ];
                //   tempGb = [
                //     ...tempGb,
                //     {
                //       id: item.id,
                //       name: item.name,
                //       path: item.path,
                //     },
                //   ];
                // });
                // this.setState({
                //   gbr_post: temp,
                //   gambar_edit: tempGb,
                // });
              } else {
                this.formRef.current.setFieldsValue({
                  [key]: data[key],
                });
              }
            }

            // if (key == "icon") {
            //   this.setState({
            //     preview_gambar: data[key],
            //   });
            // }
          }
          message.destroy();
          this.setState(
            {
              aksi: "edit",
              method: "put",
              idLama: data.id + "/",
              loadingData: false,
            },
            () => {
              if (this.focusComponent != null && this.focusComponent != "") {
                document.getElementById(this.focusComponent).focus();
              }
            }
          );
        } else {
          const errTitle = "error preview data lama";
          this.setState({
            errTitle: errTitle,
            errMsg: JSON.stringify(data),
            loadingData: false,
          });
        }
        // }, 500);
      })
      .catch((response) => {
        this.setState({
          errTitle: "gagal get data",
          errMsg: JSON.stringify(response),
          loadingData: false,
        });
      });
  }

  // editableCell = (
  //   editing,
  //   dataIndex,
  //   title,
  //   inputType,
  //   record,
  //   index,
  //   children,
  //   ...restProps
  // ) => {
  //   const inputNode = <Input />;
  //   return (
  //     <td>
  //       {editing != undefined && editing ? (
  //         <Form.Item
  //           name={dataIndex}
  //           style={{
  //             margin: 0,
  //           }}
  //           rules={[
  //             {
  //               required: true,
  //               message: `Please Input ${title}!`,
  //             },
  //           ]}
  //         >
  //           {inputNode}
  //         </Form.Item>
  //       ) : (
  //         children
  //       )}
  //     </td>
  //   );
  // };

  componentDidMount() {
    // console.log('didmount', this.props)
    this.generateYears();
    this.getData();
    const models = [
      {
        name: "tingkat_resiko",
        apiUrl: "master/api/tingkat/resiko/",
        value: "id",
        label: "nama",
      },
      {
        name: "tujuan_laporan",
        apiUrl: "pengguna/api/pengguna/",
        value: "id",
        label: "nama",
      },
      {
        name: "tugas_kepada",
        apiUrl: "pengguna/api/pengguna/",
        value: "id",
        label: "nama",
      },
      {
        name: "kunjungan_supervisi",
        apiUrl: "pengguna/api/pengguna/",
        value: "id",
        label: "nama",
      },
      {
        name: "rencana_realisasi",
        apiUrl: "pengguna/api/pengguna/",
        value: "id",
        label: "nama",
      },
      {
        name: "disetujui_oleh",
        apiUrl: "pengguna/api/pengguna/",
        value: "id",
        label: "nama",
      },
      {
        name: "disusun_oleh",
        apiUrl: "pengguna/api/pengguna/",
        value: "id",
        label: "nama",
      },
      {
        name: "mengetahui",
        apiUrl: "pengguna/api/pengguna/",
        value: "id",
        label: "nama",
      },
    ];
    models.map((item, idx) => {
      this.getMasterList(item.name, item.apiUrl);
    });
  }

  setHariRealisasi(val, idx) {
    const temp = [...this.state.hariAnggota];
    temp[idx]["hari_realisasi"] = val;
    this.setState({
      hariAnggota: temp
    });
  }

  setVal(e, stateName, idx) {
    const tempAgt = [...this.state.anggota];
    tempAgt[idx][stateName] =
      stateName == "is_kartu" ? e.target.checked : e.target.value;
    this.setState({
      anggota: tempAgt,
    });
  }

  addSupervisi() {
    let temp = this.state.supervisi;
    temp = [
      ...temp,
      {
        tipe: 1,
        tanggal_rencana: null,
        tanggal_realisasi: null,
        anggota: false,
      },
    ];
    this.setState({
      supervisi: temp,
    }, () => {

    });
  }

  addRealisasi() {
    let temp = this.state.realisasi;
    temp = [
      ...temp,
      {
        tipe: 2,
        tanggal_rencana: null,
        tanggal_realisasi: null,
        anggota: false,
      },
    ];
    this.setState({
      realisasi: temp,
    }, () => {

    });
  }

  hapusAnggota(idx) {
    const tempAgt = [...this.state.anggota];
    tempAgt.splice(idx, 1);
    this.setState({
      anggota: tempAgt,
    });
  }

  async getData(
    limit = this.state.pagination.pageSize,
    offset = 0,
    search = this.state.searchText
  ) {
    this.setState({ loadingData: true, errTitle: "", errMsg: "" });

    let baseUrl = this.props.configClass.apiUrl.data;
    let url = "";
    if (baseUrl.includes("?")) {
      url =
        baseUrl +
        "&tahun=" +
        this.state.tahunDipilih +
        "&limit=" +
        limit +
        "&offset=" +
        offset +
        "&search=" +
        search;
    } else {
      url =
        baseUrl +
        "?limit=" +
        limit +
        "&tahun=" +
        this.state.tahunDipilih +
        "&offset=" +
        offset +
        "&search=" +
        search;
    }

    if (this.state.filterJenis != "") {
      url += "&jenis=" + this.state.filterJenis;
    }

    this.props
      .ajaxViewHandler("get", url)
      .then((results) => {
        // setTimeout(() => {
        const res = this.props.responseMsg;
        if (this.props.isSuccess) {
          let pagination = { ...this.state.pagination };
          pagination.total = res.count;
          pagination.current = offset + 1;
          this.setState({
            loadingData: false,
            dataSource: res.results,
            pagination,
          });
        } else {
          const errTitle = "error get data code 1";
          this.setState({
            loadingData: false,
            errTitle: errTitle,
            errMsg: JSON.stringify(res),
          });
        }
        // }, 500);
      })
      .catch((response) => {
        const errTitle = "error get data code 2";
        this.setState({
          loadingData: false,
          errTitle: errTitle,
          errMsg: JSON.stringify(response),
        });
      });
  }

  handleTableChange = async (pagination, filters, sorter) => {
    const newPagination = { ...this.state.pagination };
    newPagination.pageSize = pagination.pageSize;
    this.setState({
      loadingData: true,
      pagination,
    });
    if (pagination.current == 1) {
      this.getData(pagination.pageSize);
    } else {
      this.getData(
        pagination.pageSize,
        (pagination.current - 1) * pagination.pageSize
      );
    }
  };

  showDeleteConfirm(item) {
    Modal.confirm({
      title: "Konfirmasi Hapus?",
      icon: <ExclamationCircleOutlined />,
      content: "yakin menghapus data?",
      okText: "Ya",
      okType: "danger",
      cancelText: "Tidak",
      onOk: () => this.prosesHapus(item),
      onCancel() {
        //console.log('Cancel');
      },
    });
  }

  resetStatePost() {
    this.setState({
      aksi: "tambah",
      method: "post",
      idLama: "",
      tambah: false,
      editingKey: "",
      open: false,
      anggota: [],
      hariAnggota: [],
      supervisi: [
        {
          tipe: 1,
          tanggal_rencana: null,
          tanggal_realisasi: null,
          anggota: false,
        },
      ],

      realisasi: [
        {
          tipe: 2,
          tanggal_rencana: null,
          tanggal_realisasi: null,
          anggota: false,
        },
      ],

      rencana_kunjungan: [],
      catatan: "",
    });
  }

  async doUploadLampiran(datas, id) {
    this.setState({
      loadingData: true,
      errTitle: "",
      errMsg: "",
    });
    // message.loading({ content: 'Memproses...', key: 'toast' });

    this.props
      .ajaxHandler(
        "put",
        this.props.configClass.apiUrl.lampiran + id + "/",
        datas,
        true,
        true
      )
      .then((res) => {
        // setTimeout(() => {
        this.setState({
          loadingData: false,
        });

        if (res.type == "SUCCESS_HANDLER") {
          notification.success({
            message: "sukses",
            description: "berhasil mengupload berkas.",
            placement: "bottomRight",
          });
          this.getData();
        } else {
          notification.error({
            message: "gagal",
            description: "gagal mengupload berkas.",
            placement: "bottomRight",
          });
          const errTitle = "gagal menyimpan data";
          // console.log("tes", res);
          this.setState({
            errTitle: errTitle,
            errMsg:
              res.error.response.data.message != undefined
                ? JSON.stringify(res.error.response.data.message)
                : errTitle,
          });
        }
        // }, 0);
      })
      .catch((response) => {
        notification.error({
          message: "gagal",
          description: "gagal mengupload berkas.",
          placement: "bottomRight",
        });
        this.setState({
          loadingData: false,
          errTitle: "gagal menyimpan",
          errMsg: JSON.stringify(response),
        });
      });
  }

  async prosesReview(status) {
    var params = new FormData();
    params.append("reference", this.state.suratTugas.id);
    params.append("status", status);
    params.append("catatan", this.state.catatan);

    this.setState({
      loadingData: true,
      errTitle: "",
      errMsg: "",
    });
    // message.loading({ content: 'Memproses...', key: 'toast' });

    this.props
      .ajaxHandler(
        "post",
        this.props.configClass.apiUrl.review,
        params,
        true,
        true
      )
      .then((res) => {
        // setTimeout(() => {
        this.setState({
          loadingData: false,
        });

        if (res.type == "SUCCESS_HANDLER") {
          notification.success({
            message: "sukses",
            description: "berhasil menyimpan review.",
            placement: "bottomRight",
          });
          this.resetStatePost();
          this.getData();
        } else {
          notification.error({
            message: "gagal",
            description: "gagal menyimpan review.",
            placement: "bottomRight",
          });
          const errTitle = "gagal menyimpan data";
          // console.log("tes", res);
          this.setState({
            errTitle: errTitle,
            errMsg:
              res.error.response.data.message != undefined
                ? JSON.stringify(res.error.response.data.message)
                : errTitle,
          });
        }
        // }, 0);
      })
      .catch((response) => {
        notification.error({
          message: "gagal",
          description: "gagal menyimpan review.",
          placement: "bottomRight",
        });
        this.setState({
          loadingData: false,
          errTitle: "gagal menyimpan",
          errMsg: JSON.stringify(response),
        });
      });
  }

  async postData(datas, id) {
    this.setState({
      loadingData: true,
      errTitle: "",
      errMsg: "",
    });
    // message.loading({ content: 'Memproses...', key: 'toast' });

    this.props
      .ajaxHandler(
        "put",
        this.props.configClass.apiUrl.preview + id + "/",
        datas,
        false,
        true
      )
      .then((res) => {
        // setTimeout(() => {
        this.setState({
          loadingData: false,
        });

        if (res.type == "SUCCESS_HANDLER") {
          notification.success({
            message: "sukses",
            description: "berhasil menyimpan.",
            placement: "bottomRight",
          });
          this.resetStatePost();
          this.getData();
        } else {
          notification.error({
            message: "gagal",
            description: "gagal menyimpan.",
            placement: "bottomRight",
          });
          const errTitle = "gagal menyimpan data";
          // console.log("tes", res);
          this.setState({
            errTitle: errTitle,
            errMsg:
              res.error.response.data.message != undefined
                ? JSON.stringify(res.error.response.data.message)
                : errTitle,
          });
        }
        // }, 0);
      })
      .catch((response) => {
        notification.error({
          message: "gagal",
          description: "gagal menyimpan.",
          placement: "bottomRight",
        });
        this.setState({
          loadingData: false,
          errTitle: "gagal menyimpan",
          errMsg: JSON.stringify(response),
        });
      });
  }

  showActiveConfirm(item) {
    Modal.confirm({
      title: "Konfirmasi Activate?",
      icon: <ExclamationCircleOutlined />,
      content: "yakin mengaktivate data?",
      okText: "Ya",
      okType: "danger",
      cancelText: "Tidak",
      onOk: () => this.prosesActivate(item),
      onCancel() {
        //console.log('Cancel');
      },
    });
  }

  prosesHapus(id) {
    this.setState({
      loadingData: true,
      errMsg: "",
      errTitle: "",
    });

    this.props
      .ajaxHandler("delete", this.props.configClass.apiUrl.hapus + id + "/")
      .then((res) => {
        if (res.type == "SUCCESS_HANDLER") {
          notification.success({
            message: "sukses",
            description: "berhasil menghapus.",
            placement: "bottomRight",
          });
          this.getData();
        } else {
          this.setState({
            errTitle: "gagal menghapus",
            errMsg: JSON.stringify(res.payload.data.message),
            loadingData: false,
          });
        }
      })
      .catch((response) => {
        this.setState({
          loadingData: false,
          errTitle: "gagal menghapus",
          errMsg: JSON.stringify(response),
        });
      });
  }

  prosesActivate(id) {
    this.setState({
      loadingData: true,
      errMsg: "",
      errTitle: "",
    });

    this.props
      .ajaxHandler(
        "get",
        this.props.configClass.apiUrl.data + "active/" + id + "/"
      )
      .then((res) => {
        if (res.type == "SUCCESS_HANDLER") {
          notification.success({
            message: "sukses",
            description: "berhasil mengactivate data.",
            placement: "bottomRight",
          });
          this.getData();
        } else {
          this.setState({
            errTitle: "gagal mengactivate data",
            errMsg: JSON.stringify(res.payload.data.message),
            loadingData: false,
          });
        }
      })
      .catch((response) => {
        this.setState({
          loadingData: false,
          errTitle: "gagal menghapus",
          errMsg: JSON.stringify(response),
        });
      });
  }

  handleKeyUp = (event) => {
    // Enter
    if (event.keyCode === 13) {
      this.formRef.current.submit();
    }
  };

  handleSubmit = (values) => {
    var params = {};
    for (var key in values) {
      if (values[key] == undefined) {
        params = { ...params, [key]: "" };
      } else {
        // datas.append(key, values[key].toString());
        params = { ...params, [key]: values[key].toString() };
      }
    }
    const rencana_kunjungan = [...this.state.supervisi, ...this.state.realisasi];
    params = { ...params, rencana_kunjungan: rencana_kunjungan };
    params = { ...params, surat_tugas: this.state.suratTugas.surat_tugas };
    params = { ...params, anggota: this.state.hariAnggota };

    // console.log(params)

    this.postData(params, this.state.suratTugas.id);
  };

  render() {
    return (
      <>
        {/*<!-- BEGIN: Content-->*/}
        <Breadcrumb>
          {this.props.breadCrumb.map((item, idx) => (
            <Breadcrumb.Item key={"bread" + idx}>{item.label}</Breadcrumb.Item>
          ))}
        </Breadcrumb>
        <div className="card">
          <Row className="isi">
            <Col span={24}>
              <Card
                className="konten"
                title={
                  <Row>
                    <Col lg={{ span: 20 }} xs={{ span: 24 }}>
                      <span className="konten-judul">
                        {this.props.configClass.name}
                      </span>
                    </Col>
                    <Col
                      lg={{ span: 4 }}
                      sm={{ span: 24 }}
                      xs={{ span: 24 }}
                      style={{ textAlign: "right" }}
                    >
                      <Space>
                        <Button
                          loading={this.state.loadingData}
                          type="default"
                          size="small"
                          onClick={() => this.getData()}
                        >
                          <RedoOutlined />
                        </Button>
                      </Space>
                    </Col>
                  </Row>
                }
              >

                <Form
                  name="finput"
                  // onKeyUp={this.handleKeyUp}
                  onFinish={this.handleSubmit}
                  autoComplete="off"
                  ref={this.formRef}
                >
                  <Table
                    bordered
                    columns={this.state.columns}
                    dataSource={this.state.dataSource}
                    loading={this.state.loadingData}
                    scroll={{ x: "max-content", y: 450 }}
                    size="small"
                    pagination={this.state.pagination}
                    rowKey={this.pk}
                    onChange={this.handleTableChange}
                    title={() => (
                      <Row>
                        <Col span={24}>
                          <Radio.Group
                            options={this.state.datajenis_surat}
                            onChange={(e) => {
                              this.setState(
                                {
                                  filterJenis: e.target.value,
                                },
                                () => {
                                  this.getData();
                                }
                              );
                            }}
                            value={this.state.filterJenis}
                            optionType="button"
                            buttonStyle="solid"
                            style={{ marginBottom: 10 }}
                          />
                        </Col>
                        <Col lg={8} sm={24} xs={24}>
                          <span className="konten-judul">
                            Total {this.state.pagination.total} record
                          </span>
                        </Col>
                        <Col lg={{ span: 8, offset: 8 }} sm={24} xs={24}>
                          <Row>
                            <Col span={6}>
                              <Form.Item
                                name="tahun"
                                rules={[
                                  {
                                    required: true,
                                    message: " harus dipilih",
                                  },
                                ]}
                              >
                                <Select
                                  size={"small"}
                                  options={this.state.yearItems}
                                  showSearch
                                  placeholder="pilih"
                                  optionFilterProp="children"
                                  onChange={(val) => {
                                    this.setState(
                                      {
                                        tahunDipilih: val,
                                      },
                                      () => {
                                        this.getData();
                                      }
                                    );
                                  }}
                                />
                              </Form.Item>
                            </Col>
                            <Col span={18}>
                              <Form.Item>
                                <Input.Search
                                  size="small"
                                  placeholder="ketikkan pencarian"
                                  onPressEnter={(e) => {
                                    e.preventDefault();
                                  }}
                                  onSearch={(value) => {
                                    this.setState(
                                      {
                                        searchText: value,
                                      },
                                      () => {
                                        this.getData();
                                      }
                                    );
                                  }}
                                  allowClear
                                />
                              </Form.Item>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    )}
                  />
                </Form>
              </Card>
            </Col>
          </Row>
        </div>
        <Modal
          title={<div style={{ textAlign: "center" }}>{"REVIEW"}</div>}
          centered
          open={this.state.openDetailReview}
          onCancel={() => this.setState({ openDetailReview: false })}
          width={"80%"}
          footer={[]}
        >
          {this.state.suratTugas != null &&
            <Row>
              <Col span={4} className="titik-dua">Status</Col>
              <Col span={20}>
                {this.state.suratTugas[this.state.jenisReview] == 1 &&
                  <Tag color={"default"}>{this.state.suratTugas[this.state.jenisReview + "_nama"]}</Tag>
                }
                {this.state.suratTugas[this.state.jenisReview] == 2 &&
                  <Tag color={"success"}>{this.state.suratTugas[this.state.jenisReview + "_nama"]}</Tag>
                }
                {this.state.suratTugas[this.state.jenisReview] == 3 &&
                  <Tag color={"warning"}>{this.state.suratTugas[this.state.jenisReview + "_nama"]}</Tag>
                }
                {this.state.suratTugas[this.state.jenisReview] == 4 &&
                  <Tag color={"error"}>{this.state.suratTugas[this.state.jenisReview + "_nama"]}</Tag>
                }
              </Col>
              <Col span={4} className="titik-dua">Catatan</Col>
              <Col span={20} dangerouslySetInnerHTML={{ __html: this.state.suratTugas[this.state.jenisReview + "_review"] != null && this.state.suratTugas[this.state.jenisReview + "_review"] != "" ? this.state.suratTugas[this.state.jenisReview + "_review"] : "-" }} />
            </Row>
          }
        </Modal>

        <Modal
          title={
            <div style={{ textAlign: "center" }}>
              {"TAMBAH " + this.props.configClass.name}
            </div>
          }
          centered
          open={this.state.open}
          onCancel={() => this.setState({ open: false })}
          width={"95%"}
          footer={[]}
        >
          <Spin spinning={this.state.loadingData}>
            <Form
              name="finsert"
              // labelCol={{
              //   span: 6,
              // }}
              // wrapperCol={{
              //   span: 18,
              // }}
              layout="vertical"
              onFinish={this.handleSubmit}
              autoComplete="off"
              ref={this.formRefModal}
            // style={{
            //   maxWidth: 600,
            // }}
            // initialValues={{
            //   remember: true,
            // }}
            >
              <Row>
                <Col span={24}>
                  <Form.Item
                    label="NOMOR SURAT"
                    name={"nomor_surat"}
                    rules={[
                      {
                        required: true,
                        message: "harus diisi",
                      },
                    ]}
                  >
                    <Input readOnly />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={[16, 16]}>
                <Col span={12}>
                  <Form.Item
                    label="NOMOR KARTU"
                    name={"nomor"}
                    rules={[
                      {
                        required: true,
                        message: "harus diisi",
                      },
                    ]}
                  >
                    <Input placeholder="Input Nomor Kartu" />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    label="NOMOR PKPT"
                    name={"nomor_pkpt"}
                  // rules={[
                  //   {
                  //     required: true,
                  //     message: "harus diisi",
                  //   },
                  // ]}
                  >
                    <Input placeholder="Input Nomor PKPT" />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={[16, 16]}>
                <Col span={12}>
                  <Form.Item
                    label="TINGKAT RESIKO"
                    name={"tingkat_resiko"}
                    rules={[
                      {
                        required: true,
                        message: "harus diisi",
                      },
                    ]}
                  >
                    {this.generateSelectAsync(
                      "tingkat_resiko",
                      "master/api/tingkat/resiko/",
                      "nama",
                      "id"
                    )}
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    label="LAPORAN DIKIRIM KEPADA"
                    name={"tujuan_laporan"}
                    rules={[
                      {
                        required: true,
                        message: "harus diisi",
                      },
                    ]}
                  >
                    {this.generateSelectAsync(
                      "tujuan_laporan",
                      "pengguna/api/pengguna/",
                      "nama",
                      "id"
                    )}
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={[16, 16]}>
                <Col span={8}>
                  <label
                    for="tugas_kepada"
                    class="ant-form-item-required"
                    title="TUGAS KEPADA"
                  >
                    TUGAS KEPADA
                  </label>
                </Col>
                <Col span={8}>
                  <label
                    for="jabatan"
                    class="ant-form-item-required"
                    title="JABATAN"
                  >
                    JABATAN
                  </label>
                </Col>
                <Col span={4}>
                  <label
                    for="hari_penugasan"
                    class="ant-form-item-required"
                    title="HARI PENUGASAN"
                  >
                    HARI PENUGASAN
                  </label>
                </Col>
                <Col span={4}>
                  <label
                    for="hari_realisasi"
                    class="ant-form-item-required"
                    title="HARI REALISASI"
                  >
                    HARI REALISASI
                  </label>
                </Col>
              </Row>

              {this.state.suratTugas != undefined && this.state.suratTugas.anggota != undefined && this.state.suratTugas.anggota.map((item, idx) => (
                <Row gutter={[16, 16]} key={"tgskpd" + idx}>
                  <Col span={8}>
                    <Form.Item>
                      <Input
                        readOnly
                        value={item.pegawai_nama == undefined ? "anggota->pegawai_nama" : item.pegawai_nama}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={8}>
                    <Form.Item>
                      <Input
                        readOnly
                        value={item.jabatan_tugas_nama == undefined ? "anggota->jabatan_tugas_nama" : item.jabatan_tugas_nama}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={4}>
                    <Form.Item>
                      <Input
                        readOnly
                        value={item.hari_tugas + " HP"}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={4}>
                    <Form.Item>
                      <InputNumber
                        value={item.hari_realisasi}
                        onChange={(val) => this.setHariRealisasi(val, idx)}
                      />
                    </Form.Item>
                  </Col>
                </Row>
              ))}

              <Row gutter={[16, 16]}>
                <Col span={24}>
                  <label
                    for="kunjungan_supervisi"
                    class="ant-form-item-required"
                    title="KUNJUNGAN SUPERVISI"
                  >
                    KUNJUNGAN SUPERVISI
                  </label>
                </Col>
              </Row>

              {this.state.supervisi.map((item, idx) => (
                <Row gutter={[16, 16]} key={"rkunjungansupervisi" + idx}>
                  <Col span={8}>
                    <Form.Item
                      label="SUPERVISI"
                      name={"supervisi" + idx}
                      rules={[
                        {
                          required: true,
                          message: "harus dipilih",
                        },
                      ]}
                    >
                      <Select
                        showSearch
                        placeholder="Pilih"
                        optionFilterProp="children"
                        onChange={(val) => {
                          let temp = [...this.state.supervisi];
                          temp[idx]["anggota"] = val;
                          this.setState({
                            supervisi: temp,
                          });
                        }}
                        filterOption={(input, option) =>
                          (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                        }
                        options={this.state.anggota}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={6}>
                    <Form.Item
                      label="TGL RENCANA"
                      name={"tgl_supervisi" + idx}
                      rules={[
                        {
                          required: true,
                          message: "harus dipilih",
                        },
                      ]}
                    >
                      <DatePicker
                        placeholder="mulai dari (dd-mm-yyyy)"
                        format="DD-MM-YYYY"
                        style={{ width: "100%" }}
                        onChange={(date, dateString) => {
                          let temp = [...this.state.supervisi];
                          temp[idx]["tanggal_rencana"] = dateString;
                          this.setState({
                            supervisi: temp,
                          });
                        }}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={6}>
                    <Form.Item
                      label="TGL REALISASI"
                      name={"tgl_realisasi_supervisi" + idx}
                      rules={[
                        {
                          required: true,
                          message: "harus dipilih",
                        },
                      ]}
                    >
                      <DatePicker
                        placeholder="mulai dari (dd-mm-yyyy)"
                        format="DD-MM-YYYY"
                        style={{ width: "100%" }}
                        onChange={(date, dateString) => {
                          let temp = [...this.state.supervisi];
                          temp[idx]["tanggal_realisasi"] = dateString;
                          this.setState({
                            supervisi: temp,
                          });
                        }}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={4} style={{ textAlign: "right" }}>
                    {
                      idx == this.state.supervisi.length - 1 ? (
                        <Form.Item label=" ">
                          <Button
                            size="small"
                            type="primary"
                            onClick={() => {
                              this.addSupervisi();
                            }}
                          >
                            <PlusOutlined /> tambah
                          </Button>
                        </Form.Item>
                      ) : (
                        ""
                      )
                    }
                  </Col>
                </Row>
              ))}


              <Row gutter={[16, 16]}>
                <Col span={24}>
                  <label
                    for="reancana_real"
                    class="ant-form-item-required"
                    title="REANCANA DAN REALISASI AUDIT"
                  >
                    REANCANA DAN REALISASI AUDIT
                  </label>
                </Col>
              </Row>

              {this.state.realisasi.map((item, idx) => (
                <Row gutter={[16, 16]} key={"rrencanarealisas" + idx}>
                  <Col span={8}>
                    <Form.Item
                      label="PETUGAS"
                      name={"petugas_realisasi" + idx}
                      rules={[
                        {
                          required: true,
                          message: "harus dipilih",
                        },
                      ]}
                    >
                      <Select
                        showSearch
                        placeholder="Pilih"
                        optionFilterProp="children"
                        onChange={(val) => {
                          let temp = [...this.state.realisasi];
                          temp[idx]["anggota"] = val;
                          this.setState({
                            realisasi: temp,
                          });
                        }}
                        filterOption={(input, option) =>
                          (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                        }
                        options={this.state.anggota}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={6}>
                    <Form.Item
                      label="TGL RENCANA"
                      name={"rencana_realisasi" + idx}
                      rules={[
                        {
                          required: true,
                          message: "harus dipilih",
                        },
                      ]}
                    >
                      <DatePicker
                        placeholder="mulai dari (dd-mm-yyyy)"
                        format="DD-MM-YYYY"
                        style={{ width: "100%" }}
                        onChange={(date, dateString) => {
                          let temp = [...this.state.realisasi];
                          temp[idx]["tanggal_rencana"] = dateString;
                          this.setState({
                            realisasi: temp,
                          });
                        }}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={6}>
                    <Form.Item
                      label="TGL REALISASI"
                      name={"tgl_realisasi" + idx}
                      rules={[
                        {
                          required: true,
                          message: "harus dipilih",
                        },
                      ]}
                    >
                      <DatePicker
                        placeholder="mulai dari (dd-mm-yyyy)"
                        format="DD-MM-YYYY"
                        style={{ width: "100%" }}
                        onChange={(date, dateString) => {
                          let temp = [...this.state.realisasi];
                          temp[idx]["tanggal_realisasi"] = dateString;
                          this.setState({
                            realisasi: temp,
                          });
                        }}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={4} style={{ textAlign: "right" }}>
                    {
                      idx == this.state.realisasi.length - 1 ? (
                        <Form.Item label=" ">
                          <Button
                            size="small"
                            type="primary"
                            onClick={() => {
                              this.addRealisasi();
                            }}
                          >
                            <PlusOutlined /> tambah
                          </Button>
                        </Form.Item>
                      ) : (
                        ""
                      )
                    }
                  </Col>
                </Row>
              ))}


              <Row gutter={[16, 16]}>
                <Col span={8}>
                  <Form.Item
                    label="TGL PERKIRAAN LAPORAN SELESAI"
                    name={"perkiraan_selesai_picker"}
                    rules={[
                      {
                        required: true,
                        message: "harus diisi",
                      },
                    ]}
                  >
                    <DatePicker
                      placeholder="mulai dari (dd-mm-yyyy)"
                      format="DD-MM-YYYY"
                      style={{ width: "100%" }}
                      onChange={(date, dateString) => {
                        this.formRefModal.current.setFieldsValue({
                          perkiraan_selesai: dateString,
                        });
                      }}
                    />
                  </Form.Item>
                  <Form.Item style={{ display: "none" }} name={"perkiraan_selesai"}>
                    <Input type="hidden" />
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Form.Item
                    label="TGL KARTU"
                    name={"tanggal_kartu_picker"}
                    rules={[
                      {
                        required: true,
                        message: "harus diisi",
                      },
                    ]}
                  >
                    <DatePicker
                      placeholder="mulai dari (dd-mm-yyyy)"
                      format="DD-MM-YYYY"
                      style={{ width: "100%" }}
                      onChange={(date, dateString) => {
                        this.formRefModal.current.setFieldsValue({
                          tanggal_kartu: dateString,
                        });
                      }}
                    />
                  </Form.Item>
                  <Form.Item style={{ display: "none" }} name={"tanggal_kartu"}>
                    <Input type="hidden" />
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col span={24}>
                  <Form.Item
                    label="DI SETUJUI OLEH"
                    name={"disetujui_oleh"}
                    rules={[
                      {
                        required: true,
                        message: "harus dipilih",
                      },
                    ]}
                  >
                    {this.generateSelectAsync(
                      "disetujui_oleh",
                      "pengguna/api/pengguna/",
                      "nama",
                      "id"
                    )}
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col span={24}>
                  <Form.Item
                    label="DI SUSUN OLEH"
                    name={"disusun_oleh"}
                    rules={[
                      {
                        required: true,
                        message: "harus dipilih",
                      },
                    ]}
                  >
                    {this.generateSelectAsync(
                      "disusun_oleh",
                      "pengguna/api/pengguna/",
                      "nama",
                      "id"
                    )}
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col span={24}>
                  <Form.Item
                    label="MENGETAHUI"
                    name={"mengetahui"}
                    rules={[
                      {
                        required: true,
                        message: "harus dipilih",
                      },
                    ]}
                  >
                    {this.generateSelectAsync(
                      "mengetahui",
                      "pengguna/api/pengguna/",
                      "nama",
                      "id"
                    )}
                  </Form.Item>
                </Col>
              </Row>
              <Form.Item>
                <Space>

                  <Button htmlType="submit" type="primary" title="simpan">
                    <SaveOutlined /> Simpan
                  </Button>


                  <Button
                    htmlType="button"
                    type="primary"
                    danger
                    title="batalkan"
                    onClick={() => {
                      this.setState({
                        open: false,
                      });
                    }}
                  >
                    <CloseSquareOutlined /> Batal
                  </Button>
                </Space>
              </Form.Item>
            </Form>
          </Spin>
        </Modal>

        <Modal
          title={<div style={{ textAlign: "center" }}>{"REVIEW KARTU TUGAS"}</div>}
          centered
          open={this.state.openReview}
          onCancel={() => this.setState({ openReview: false })}
          width={"90%"}
          footer={[]}
        >
          <Spin spinning={this.state.loadingData}>
            <>
              {this.state.loadingEditor && <Spin size="large" />}
              <Editor
                apiKey="31p2mm6w8u0tr9ns1q8np6momklzza9j90vt2wcdjiw4esvy"
                init={{
                  height: 300,
                }}
                onEditorChange={(content, editor) => {
                  this.setState({
                    catatan: content,
                  });
                }}
                onInit={() => {
                  this.setState({
                    loadingEditor: false,
                  });
                }}
                value={this.state.catatan}
              />
              {this.state.loadingEditor == false &&
                <Row justify="end" style={{ marginTop: 20 }} gutter={[16, 16]}>
                  <Col>
                    <Popconfirm
                      title="yakin menerima kartu tugas?"
                      onConfirm={() => this.prosesReview(2)}
                      okText="Ya"
                      cancelText="Tidak"
                      okButtonProps={{
                        type: "primary",
                        danger: true,
                      }}
                      cancelButtonProps={{
                        type: "primary",
                      }}
                    >
                      <Button
                        type="primary"
                        success
                      >
                        Terima
                      </Button>
                    </Popconfirm>
                  </Col>
                  <Col>
                    <Popconfirm
                      title="yakin menerima kartu tugas dengan catatan?"
                      onConfirm={() => this.prosesReview(3)}
                      okText="Ya"
                      cancelText="Tidak"
                      okButtonProps={{
                        type: "primary",
                        danger: true,
                      }}
                      cancelButtonProps={{
                        type: "primary",
                      }}
                    >
                      <Button
                        type="primary"
                        style={{
                          background: "#FDBD19"
                        }}
                      >
                        Terima dengan Catatan
                      </Button>
                    </Popconfirm>
                  </Col>
                  <Col>
                    <Popconfirm
                      title="yakin menolak kartu tugas?"
                      onConfirm={() => this.prosesReview(4)}
                      okText="Ya"
                      cancelText="Tidak"
                      okButtonProps={{
                        type: "primary",
                        danger: true,
                      }}
                      cancelButtonProps={{
                        type: "primary",
                      }}
                    >
                      <Button
                        type="primary"
                        danger
                      >
                        Tolak
                      </Button>
                    </Popconfirm>
                  </Col>
                </Row>
              }
            </>
          </Spin>
        </Modal>
      </>
    );
  }
}

const mapStateToProps = function (state) {
  return state.configReducers;
};

export default connect(mapStateToProps, {
  setConfig,
  ajaxHandler,
  ajaxViewHandler,
})(withRouter(ReviewSupervisi));
