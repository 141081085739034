import React, { PureComponent } from "react";
import { Redirect, Link } from "react-router-dom";
import { withRouter } from "react-router";
import {
    Button,
    Row,
    Col,
    Input,
    Layout,
    Form,
    Alert,
    Modal,
    message,
    Switch,
    Tabs,
    notification,
    Spin,
    Pagination,
    Tag,
    Breadcrumb,
    Card,
    Space,
    Table,
    Popconfirm,
    Skeleton,
    Select,
    InputNumber,
    Radio,
    Checkbox,
    DatePicker,
} from "antd";
import {
    TeamOutlined,
    SearchOutlined,
    PlusOutlined,
    FormOutlined,
    CloseSquareOutlined,
    ExclamationCircleOutlined,
    DiffOutlined,
    RedoOutlined,
    EditOutlined,
    DeleteOutlined,
    DownloadOutlined,
    SaveOutlined,
    BookOutlined,
    CopyOutlined
} from "@ant-design/icons";
// import "@ant-design/compatible/assets/index.css";
import Config from "../../Config";
import { Helmet } from "react-helmet";

import { Editor } from "@tinymce/tinymce-react";

import { connect } from "react-redux";
import { setConfig, ajaxHandler, ajaxViewHandler } from "../../store/actions";

import moment from "moment";

import axios from "axios";
import Cookies from "js-cookie";

class FormReviewSupervisi extends PureComponent {
    config;
    formRef = React.createRef();
    focusComponent = "";
    pk = "";

    constructor(props) {
        super(props);
        this.config = new Config();
        this.state = {
            openDetailReview: false,
            jenisReview: "approval_satu",
            openReview: false,
            catatan: "",
            loadingEditor: true,
            suratTugas: null,
            errTitle: "",
            errMsg: "",
            loadingData: false,

            aksi: "Edit",
            method: "post",
            idLama: "",
            editingKey: "",
            open: false,
            filterJenis: 1,
            masalah: [],

            isSuccess: false,

            // masalah: [
            //     {
            //         masalah: "",
            //         penyelesaian: "",
            //         halaman: "",
            //         dilakukan_oleh: "",
            //         keterangan: "",
            //     },
            // ],


        };
    }

    addMasalah() {
        let temp = this.state.masalah;
        temp = [
            ...temp,
            {
                masalah: "",
                penyelesaian: "",
                halaman: "",
                dilakukan_oleh: "",
                keterangan: "",
            },
        ];
        this.setState({
            masalah: temp,
        }, () => {

        });
    }

    async getMasterList(stateName, url, isNext = false) {
        this.setState({
            ["loading" + stateName]: !isNext,
            ["loadingNext" + stateName]: isNext,
            ["loadingSearch" + stateName]: isNext,
        });
        this.props
            .ajaxViewHandler("get", url)
            .then(() => {
                const data = this.props.responseMsg;
                if (this.props.isSuccess) {
                    if (data.count != undefined) {
                        let newState = null;
                        if (
                            this.state["data" + stateName] == undefined ||
                            this.state["data" + stateName] == null
                        ) {
                            newState = data;
                        } else {
                            if (data.previous == null) {
                                //first data
                                newState = data;
                            } else {
                                const oldState = this.state["data" + stateName];
                                newState = {
                                    count: data.count,
                                    next: data.next,
                                    previous: data.previous,
                                    results: [...oldState.results, ...data.results],
                                };
                            }
                        }
                        // console.log('state_name', destination.stateDestination);
                        // console.log('state_value', newState);
                        this.setState(
                            {
                                ["data" + stateName]: newState,
                            },
                            () => {
                                if (stateName == "jenis_surat") {
                                    let temp = [];
                                    data.results.map((item, idx) => {
                                        temp = [
                                            ...temp,
                                            {
                                                value: item.id,
                                                label: item.nama,
                                            },
                                        ];
                                    });
                                    this.setState({
                                        ["data" + stateName]: temp,
                                    });
                                }
                            }
                        );
                    } else if (data.results != undefined) {
                        this.setState({
                            ["data" + stateName]: data.results,
                        });
                    } else {
                        if (Array.isArray(data)) {
                            this.setState({
                                ["data" + stateName]: data,
                            });
                        } else {
                            this.setState({
                                ["data" + stateName]: [data],
                            });
                        }
                    }
                } else {
                    console.log(data);
                }
                this.setState({
                    ["loading" + stateName]: false,
                    ["loadingNext" + stateName]: false,
                    ["loadingSearch" + stateName]: false,
                });
            })
            .catch((response) => {
                console.log(response);
                this.setState({
                    ["loading" + stateName]: false,
                    ["loadingNext" + stateName]: false,
                    ["loadingSearch" + stateName]: false,
                });
            });
    }

    componentDidMount() {
        // console.log('didmount', this.props)
        this.setState({
            aksi: this.props.match.params.aksi
        })

        this.preview();
    }

    async preview() {
        this.setState({ loadingData: true, errTitle: "", errMsg: "" });

        let url = this.props.configClass.apiUrl.data + this.props.match.params.id;

        this.props
            .ajaxViewHandler("get", url)
            .then((results) => {
                // setTimeout(() => {
                const res = this.props.responseMsg;
                if (this.props.isSuccess) {
                    let temp_masalah = res.masalah;
                    this.setState({
                        loadingData: false,
                        masalah: temp_masalah,
                    });
                    this.formRef.current.setFieldsValue({
                        judul_lhp: res.nama
                    })
                } else {
                    const errTitle = "error get data code 1";
                    this.setState({
                        loadingData: false,
                        errTitle: errTitle,
                        errMsg: JSON.stringify(res),
                    });
                }
                // }, 500);
            })
            .catch((response) => {
                const errTitle = "error get data code 2";
                this.setState({
                    loadingData: false,
                    errTitle: errTitle,
                    errMsg: JSON.stringify(response),
                });
            });
    }

    async postData(datas) {
        this.setState({
            loadingData: true,
            errTitle: "",
            errMsg: "",
        });
        // message.loading({ content: 'Memproses...', key: 'toast' });

        this.props
            .ajaxHandler(
                "put",
                this.props.configClass.apiUrl.preview + this.props.match.params.id + "/",
                datas,
                false,
                true
            )
            .then((res) => {
                // setTimeout(() => {
                this.setState({
                    loadingData: false,
                });

                if (res.type == "SUCCESS_HANDLER") {
                    notification.success({
                        message: "sukses",
                        description: "berhasil menyimpan.",
                        placement: "bottomRight",
                    });
                    this.setState({
                        isSuccess: true
                    })
                } else {
                    notification.error({
                        message: "gagal",
                        description: "gagal menyimpan.",
                        placement: "bottomRight",
                    });
                    const errTitle = "gagal menyimpan data";
                    // console.log("tes", res);
                    this.setState({
                        errTitle: errTitle,
                        errMsg:
                            res.error.response.data.message != undefined
                                ? JSON.stringify(res.error.response.data.message)
                                : errTitle,
                    });
                }
                // }, 0);
            })
            .catch((response) => {
                notification.error({
                    message: "gagal",
                    description: "gagal menyimpan.",
                    placement: "bottomRight",
                });
                this.setState({
                    loadingData: false,
                    errTitle: "gagal menyimpan",
                    errMsg: JSON.stringify(response),
                });
            });
    }

    handleSubmit = (values) => {
        var params = {};
        for (var key in values) {
            if (values[key] == undefined) {
                params = { ...params, [key]: "" };
            } else {
                // datas.append(key, values[key].toString());
                params = { ...params, [key]: values[key].toString() };
            }
        }
        const permasalahan = [...this.state.masalah];
        params = { ...params, masalah: permasalahan };

        // console.log(params)

        this.postData(params);
    };

    render() {
        if (this.state.isSuccess) {
            return <Redirect to="/admin/pelaporan/review-sheet-supervisi" />
        }
        return (
            <>
                {/*<!-- BEGIN: Content-->*/}
                <Breadcrumb>
                    {this.props.breadCrumb.map((item, idx) => (
                        <Breadcrumb.Item key={"bread" + idx}>{item.label}</Breadcrumb.Item>
                    ))}
                </Breadcrumb>
                <div className="card">
                    <Row className="isi">
                        <Col span={24}>
                            <Card
                                className="konten"
                                title={
                                    <Row>
                                        <Col lg={{ span: 20 }} xs={{ span: 24 }}>
                                            <span className="konten-judul">
                                                {this.state.aksi + " " + this.props.configClass.name}
                                            </span>
                                        </Col>
                                    </Row>
                                }
                            >

                                <Spin
                                    spinning={this.state.loadingData}
                                >
                                    <Form
                                        name="finput"
                                        // onKeyUp={this.handleKeyUp}
                                        onFinish={this.handleSubmit}
                                        autoComplete="off"
                                        ref={this.formRef}
                                        layout="vertical"
                                        disabled={this.state.aksi.toLowerCase() == "view" ? true : false}

                                    >
                                        <Row>
                                            <Col span={24}>
                                                <Form.Item
                                                    label="JUDUL LHP"
                                                    name={"judul_lhp"}
                                                    disabled
                                                >
                                                    <Input />
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                        {this.state.masalah.map((item, idx) => (
                                            <Row gutter={[8, 8]} key={"rmasalah" + idx} style={{ border: "1px solid #ccc", padding: 10 }}>
                                                <Col span={24}>
                                                    <Form.Item
                                                        label="HALAMAN LHP"
                                                        // name={"masalah" + idx}
                                                        rules={[
                                                            {
                                                                required: true,
                                                                message: "harus diisi",
                                                            },
                                                        ]}
                                                    >
                                                        <Input
                                                            placeholder="Input Halaman LHP"
                                                            onChange={(e) => {
                                                                let temp = [...this.state.masalah];
                                                                temp[idx]["halaman"] = e.target.value;
                                                                this.setState({
                                                                    masalah: temp,
                                                                });
                                                            }}
                                                            value={item.halaman}
                                                        />
                                                    </Form.Item>
                                                </Col>
                                                <Col span={24}>
                                                    <Form.Item
                                                        label="MASALAH YANG DIJUMPAI"
                                                        // name={"masalah" + idx}
                                                        rules={[
                                                            {
                                                                required: true,
                                                                message: "harus diisi",
                                                            },
                                                        ]}
                                                    >
                                                        <Input
                                                            placeholder="Input Permasalahan"
                                                            onChange={(e) => {
                                                                let temp = [...this.state.masalah];
                                                                temp[idx]["masalah"] = e.target.value;
                                                                this.setState({
                                                                    masalah: temp,
                                                                });
                                                            }}
                                                            value={item.masalah}
                                                        />
                                                    </Form.Item>
                                                </Col>
                                                <Col span={24}>
                                                    <Form.Item
                                                        label="PENYELESAIAN"
                                                        // name={"penyelesaian" + idx}
                                                        rules={[
                                                            {
                                                                required: true,
                                                                message: "harus diisi",
                                                            },
                                                        ]}
                                                    >
                                                        <Input
                                                            placeholder="Input Penyelesaian"
                                                            onChange={(e) => {
                                                                let temp = [...this.state.masalah];
                                                                temp[idx]["penyelesaian"] = e.target.value;
                                                                this.setState({
                                                                    masalah: temp,
                                                                });
                                                            }}
                                                            value={item.penyelesaian}
                                                        />
                                                    </Form.Item>
                                                </Col>
                                                <Col span={24}>
                                                    <Form.Item
                                                        label="DILAKUKAN OLEH"
                                                        // name={"penyelesaian" + idx}
                                                        rules={[
                                                            {
                                                                required: true,
                                                                message: "harus diisi",
                                                            },
                                                        ]}
                                                    >
                                                        <Input
                                                            disabled
                                                            placeholder="otomatis oleh ketua tim"
                                                            onChange={(e) => {
                                                                let temp = [...this.state.masalah];
                                                                temp[idx]["dilakukan_oleh"] = e.target.value;
                                                                this.setState({
                                                                    masalah: temp,
                                                                });
                                                            }}
                                                            value={item.dilakukan_oleh}
                                                        />
                                                    </Form.Item>
                                                </Col>
                                                <Col span={24}>
                                                    <Form.Item
                                                        label="KETERANGAN"
                                                        // name={"penyelesaian" + idx}
                                                        rules={[
                                                            {
                                                                required: true,
                                                                message: "harus diisi",
                                                            },
                                                        ]}
                                                    >
                                                        <Input
                                                            placeholder="Input Keterangan"
                                                            onChange={(e) => {
                                                                let temp = [...this.state.masalah];
                                                                temp[idx]["keterangan"] = e.target.value;
                                                                this.setState({
                                                                    masalah: temp,
                                                                });
                                                            }}
                                                            value={item.keterangan}
                                                        />
                                                    </Form.Item>
                                                </Col>
                                            </Row>
                                        ))}
                                        <Row style={{ marginTop: 5 }}>
                                            <Col span={24} style={{ textAlign: "right" }}>
                                                <Button
                                                    key={"btntambah"}
                                                    size="small"
                                                    type="primary"
                                                    onClick={() => {
                                                        this.addMasalah();
                                                    }}
                                                >
                                                    <PlusOutlined /> tambah
                                                </Button>
                                            </Col>
                                        </Row>
                                        <Form.Item
                                            wrapperCol={{
                                                offset: 0,
                                                span: 24,
                                            }}
                                        >
                                            <Space>
                                                <Button type="primary" htmlType="submit">
                                                    <SaveOutlined />
                                                    Simpan
                                                </Button>
                                                <Link to="/admin/pelaporan/review-sheet-supervisi">
                                                    <Button
                                                        type="primary"
                                                        danger
                                                        htmlType="button"
                                                        onClick={() => {
                                                            this.setState({
                                                                openKeterangan: false,
                                                            });
                                                        }}
                                                        disabled={false}
                                                    >
                                                        <CloseSquareOutlined />
                                                        Batal
                                                    </Button>
                                                </Link>
                                            </Space>
                                        </Form.Item>

                                    </Form>
                                </Spin>
                            </Card>
                        </Col>
                    </Row>
                </div>
            </>
        );
    }
}

const mapStateToProps = function (state) {
    return state.configReducers;
};

export default connect(mapStateToProps, {
    setConfig,
    ajaxHandler,
    ajaxViewHandler,
})(withRouter(FormReviewSupervisi));
