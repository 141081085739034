import React, { PureComponent } from "react";
import { Redirect, Link } from "react-router-dom";
import { withRouter } from "react-router";
import {
    Button,
    Row,
    Col,
    Input,
    Layout,
    Form,
    Alert,
    Modal,
    message,
    Switch,
    Tabs,
    notification,
    Spin,
    Pagination,
    Tag,
    Breadcrumb,
    Card,
    Space,
    Table,
    Popconfirm,
    Skeleton,
    Select,
    InputNumber,
    Radio,
    Checkbox,
    DatePicker,
} from "antd";
import {
    TeamOutlined,
    SearchOutlined,
    PlusOutlined,
    FormOutlined,
    CloseSquareOutlined,
    ExclamationCircleOutlined,
    DiffOutlined,
    RedoOutlined,
    EditOutlined,
    DeleteOutlined,
    DownloadOutlined,
    SaveOutlined,
    BookOutlined
} from "@ant-design/icons";
// import "@ant-design/compatible/assets/index.css";
import Config from "../../Config";
import { Helmet } from "react-helmet";

import { Editor } from "@tinymce/tinymce-react";

import { connect } from "react-redux";
import { setConfig, ajaxHandler, ajaxViewHandler } from "../../store/actions";

import moment from "moment";

import axios from "axios";
import Cookies from "js-cookie";

class DaftarFileMaster extends PureComponent {
    config;
    formRef = React.createRef();
    formRefModal = React.createRef();
    focusComponent = "";
    pk = "";

    constructor(props) {
        super(props);
        this.config = new Config();
        this.state = {
            openDetailReview: false,
            jenisReview: "approval_satu",
            openReview: false,
            catatan: "",
            loadingEditor: true,
            suratTugas: null,
            errTitle: "",
            errMsg: "",
            loadingData: false,
            pagination: { pageSize: 10, current: 1, total: 0 },
            searchText: "",
            dataSource: [],
            prevSource: [],
            columns: [
                {
                    title: "Nomor",
                    width: 90,
                    dataIndex: "id",
                    key: "nomor",
                    align: "center",
                    render: (value, row, index) => {
                        const counterNumber = (this.state.pagination.current - 1) * this.state.pagination.pageSize + index + 1
                        return counterNumber;
                    }
                },
                {
                    title: "Nama File",
                    width: 150,
                    dataIndex: "nama_file",
                    key: "nama_file",
                },
                {
                    title: "Nomor Urut Dokumen",
                    width: 150,
                    dataIndex: "nomor_urut",
                    key: "nomor_urut",
                },
                {
                    title: "Aksi",
                    width: 150,
                    fixed: "right",
                    dataIndex: "id",
                    key: "aksi",
                    align: "center",
                    render: (value, row, index) => {
                        return (
                            <Space>
                                <Link to={"form/" + value}>
                                    <Button
                                        size="small"
                                        title="edit"
                                        type="primary"
                                        ghost
                                        default
                                        htmlType="button"
                                    >
                                        <BookOutlined />
                                    </Button>
                                </Link>
                                <Button
                                    size="small"
                                    type="primary"
                                    ghost
                                    danger
                                    htmlType="button"
                                    onClick={() => {
                                        this.showDeleteConfirm(value)
                                    }}
                                >
                                    <DeleteOutlined />
                                </Button>
                            </Space>
                        )
                    },
                },
            ],
            tahunDipilih: new Date().getFullYear(),
            tambah: false,
            aksi: "tambah",
            method: "post",
            idLama: "",
            editingKey: "",
            open: false,
            filterJenis: 1,
        };
    }

    async getMasterList(stateName, url, isNext = false) {
        this.setState({
            ["loading" + stateName]: !isNext,
            ["loadingNext" + stateName]: isNext,
            ["loadingSearch" + stateName]: isNext,
        });
        this.props
            .ajaxViewHandler("get", url)
            .then(() => {
                const data = this.props.responseMsg;
                if (this.props.isSuccess) {
                    if (data.count != undefined) {
                        let newState = null;
                        if (
                            this.state["data" + stateName] == undefined ||
                            this.state["data" + stateName] == null
                        ) {
                            newState = data;
                        } else {
                            if (data.previous == null) {
                                //first data
                                newState = data;
                            } else {
                                const oldState = this.state["data" + stateName];
                                newState = {
                                    count: data.count,
                                    next: data.next,
                                    previous: data.previous,
                                    results: [...oldState.results, ...data.results],
                                };
                            }
                        }
                        // console.log('state_name', destination.stateDestination);
                        // console.log('state_value', newState);
                        this.setState(
                            {
                                ["data" + stateName]: newState,
                            },
                            () => {
                                if (stateName == "jenis_surat") {
                                    let temp = [];
                                    data.results.map((item, idx) => {
                                        temp = [
                                            ...temp,
                                            {
                                                value: item.id,
                                                label: item.nama,
                                            },
                                        ];
                                    });
                                    this.setState({
                                        ["data" + stateName]: temp,
                                    });
                                }
                            }
                        );
                    } else if (data.results != undefined) {
                        this.setState({
                            ["data" + stateName]: data.results,
                        });
                    } else {
                        if (Array.isArray(data)) {
                            this.setState({
                                ["data" + stateName]: data,
                            });
                        } else {
                            this.setState({
                                ["data" + stateName]: [data],
                            });
                        }
                    }
                } else {
                    console.log(data);
                }
                this.setState({
                    ["loading" + stateName]: false,
                    ["loadingNext" + stateName]: false,
                    ["loadingSearch" + stateName]: false,
                });
            })
            .catch((response) => {
                console.log(response);
                this.setState({
                    ["loading" + stateName]: false,
                    ["loadingNext" + stateName]: false,
                    ["loadingSearch" + stateName]: false,
                });
            });
    }

    generateYears = () => {
        var start = 2020;
        var end = new Date().getFullYear() + 3;
        var items = [];
        for (var i = start; i <= end; i++) {
            items = [
                ...items,
                {
                    value: i,
                    label: i,
                },
            ];
        }
        this.setState(
            {
                yearItems: items,
            },
            () => {
                // console.log('year', this.state.yearItems)
                this.formRef.current.setFieldsValue({
                    tahun: new Date().getFullYear(),
                });
            }
        );
    };

    generateSelectAsync = (stateName, url, label, value, idxAnggota = -1) => {
        return this.state["loading" + stateName] ? (
            <Skeleton active paragraph={false} />
        ) : (
            <Select
                style={{ minWidth: 200 }}
                dropdownMatchSelectWidth={true}
                id={stateName}
                showSearch
                placeholder="pilih"
                optionFilterProp="children"
                allowClear
                onSearch={(val) => {
                    let tempUrl = "";
                    if (url.includes("?")) {
                        tempUrl = url + "&search=" + val;
                    } else {
                        tempUrl = url + "?search=" + val;
                    }
                    this.getMasterList(stateName, tempUrl, false);
                }}
                onPopupScroll={(e) => {
                    e.persist();
                    let target = e.target;
                    if (target.scrollTop + target.offsetHeight >= target.scrollHeight) {
                        if (
                            this.state["data" + stateName].next != undefined &&
                            this.state["data" + stateName].next != null
                        ) {
                            this.getMasterList(
                                stateName,
                                this.state["data" + stateName].next,
                                true
                            );
                        }
                    }
                }}
                onChange={(val) => {
                    if (stateName == "pegawai" || stateName == "jabatan_tugas") {
                        let temp = [...this.state.anggota];
                        temp[idxAnggota][stateName] = val;
                        this.setState({
                            anggota: temp,
                        });
                    }
                }}
            >
                {this.state["loadingSearch" + stateName] && (
                    <Skeleton active paragraph={false} />
                )}
                {this.state["data" + stateName] != undefined &&
                    this.state["data" + stateName] != null && (
                        <>
                            {this.state["data" + stateName].results != undefined
                                ? this.state["data" + stateName].results.map((item) => (
                                    <Select.Option value={item[value]}>
                                        {item[label]}
                                    </Select.Option>
                                ))
                                : this.state["data" + stateName].map((item) => (
                                    <Select.Option value={item[value]}>
                                        {item[label]}
                                    </Select.Option>
                                ))}
                        </>
                    )}
                {this.state["loadingNext" + stateName] && (
                    <Skeleton active paragraph={false} />
                )}
            </Select>
        );
    };

    setFile(e, name, id) {
        this.setState(
            {
                [name + "_file"]: e.target.files[0],
            },
            () => {
                // console.log(this.state[name+"_file"])
                let params = new FormData();
                params.append("lampiran", this.state[name + "_file"], this.state[name + "_file"].name)
                this.doUploadLampiran(params, id);
            }
        );
    }

    showReview(row) {
        this.setState(
            {
                suratTugas: row,
                openReview: true,
            },
            () => {
            }
        );
    }

    showInsert(row) {
        let tempAnggota = [];
        if (row.anggota != undefined) {
            row.anggota.map((item, idx) => {
                tempAnggota = [...tempAnggota, {
                    label: item.pegawai_nama == undefined ? "anggota->pegawai_nama" : item.pegawai_nama,
                    value: item.pegawai,
                }];
            });
        }
        this.setState(
            {
                suratTugas: row,
                open: true,
                aksi: "tambah",
                idLama: "",
                method: "post",
                editingKey: "",
                anggota: tempAnggota,
                hariAnggota: row.anggota,
            },
            () => {
                this.formRefModal.current.resetFields();
                this.formRefModal.current.setFieldsValue({
                    nomor_surat: row.surat_tugas_nomor
                });
            }
        );
    }

    showEdit = (record) => {
        this.formRef.current.resetFields();

        let tempData = this.state.dataSource;

        this.setState(
            {
                prevSource: tempData,
                editingKey: record.id,
            },
            () => {
                this.preview(record.id);
            }
        );
        // this.formRef.current.setFieldsValue({
        //   nama: '',
        //   ...record,
        // });
        // this.setState({
        //   editingKey: record.id
        // }, console.log('editingkey', record.id));
    };

    async preview(id) {
        this.setState({
            loadingData: true,
            errTitle: "",
            errMsg: "",
        });

        this.props
            .ajaxViewHandler("get", this.props.configClass.apiUrl.preview + id)
            .then(() => {
                // setTimeout(() => {
                const data = this.props.responseMsg;
                if (this.props.isSuccess) {
                    // this.formRef.current.setFieldsValue(data);
                    for (var key in data) {
                        const dateRegex = /^[0-9]{4}\-[0-9]{2}\-[0-9]{2}$/;
                        const dateTimeRegex =
                            /^[0-9]{4}\-[0-9]{2}\-[0-9]{2} [0-9]{2}\:[0-9]{2}\:[0-9]{2}$/;
                        if (dateRegex.test(data[key])) {
                            //jika format tanggal
                            this.formRef.current.setFieldsValue({
                                [key + "_picker"]: moment(new Date(data[key]), "DD-MM-YYYY"),
                                [key]: moment(new Date(data[key]), "DD-MM-YYYY"),
                            });
                        } else if (dateTimeRegex.test(data[key])) {
                            //jika format tanggal dan jam
                            this.formRef.current.setFieldsValue({
                                [key + "_picker"]: moment(
                                    new Date(data[key]),
                                    "DD-MM-YYYY HH:mm:ss"
                                ),
                                [key]: moment(new Date(data[key]), "DD-MM-YYYY HH:mm:ss"),
                            });
                        } else {
                            if (key == "body") {
                                this.setState({
                                    artikelString: data[key],
                                });
                                this.formRef.current.setFieldsValue({
                                    content_editor: data[key],
                                    body_editor: data[key],
                                });
                            } else if (key == "tag") {
                                let temp = [];
                                data[key].map((item) => {
                                    temp = [
                                        ...temp,
                                        {
                                            value: item.id,
                                            label: item.nama,
                                            key: item.id,
                                        },
                                    ];
                                });
                                this.formRef.current.setFieldsValue({
                                    tags: temp,
                                });
                            } else if (key == "image" || key == "dokumen") {
                                // let temp = [];
                                // let tempGb = [];
                                // data[key].map((item) => {
                                //   temp = [
                                //     ...temp,
                                //     {
                                //       id: item.id,
                                //       name: item.name,
                                //     },
                                //   ];
                                //   tempGb = [
                                //     ...tempGb,
                                //     {
                                //       id: item.id,
                                //       name: item.name,
                                //       path: item.path,
                                //     },
                                //   ];
                                // });
                                // this.setState({
                                //   gbr_post: temp,
                                //   gambar_edit: tempGb,
                                // });
                            } else {
                                this.formRef.current.setFieldsValue({
                                    [key]: data[key],
                                });
                            }
                        }

                        // if (key == "icon") {
                        //   this.setState({
                        //     preview_gambar: data[key],
                        //   });
                        // }
                    }
                    message.destroy();
                    this.setState(
                        {
                            aksi: "edit",
                            method: "put",
                            idLama: data.id + "/",
                            loadingData: false,
                        },
                        () => {
                            if (this.focusComponent != null && this.focusComponent != "") {
                                document.getElementById(this.focusComponent).focus();
                            }
                        }
                    );
                } else {
                    const errTitle = "error preview data lama";
                    this.setState({
                        errTitle: errTitle,
                        errMsg: JSON.stringify(data),
                        loadingData: false,
                    });
                }
                // }, 500);
            })
            .catch((response) => {
                this.setState({
                    errTitle: "gagal get data",
                    errMsg: JSON.stringify(response),
                    loadingData: false,
                });
            });
    }

    componentDidMount() {
        // console.log('didmount', this.props)
        this.generateYears();
        this.getData();
        const models = [
            {
                name: "tingkat_resiko",
                apiUrl: "master/api/tingkat/resiko/",
                value: "id",
                label: "nama",
            },
            {
                name: "tujuan_laporan",
                apiUrl: "pengguna/api/pengguna/",
                value: "id",
                label: "nama",
            },
            {
                name: "tugas_kepada",
                apiUrl: "pengguna/api/pengguna/",
                value: "id",
                label: "nama",
            },
            {
                name: "kunjungan_supervisi",
                apiUrl: "pengguna/api/pengguna/",
                value: "id",
                label: "nama",
            },
            {
                name: "rencana_realisasi",
                apiUrl: "pengguna/api/pengguna/",
                value: "id",
                label: "nama",
            },
            {
                name: "disetujui_oleh",
                apiUrl: "pengguna/api/pengguna/",
                value: "id",
                label: "nama",
            },
            {
                name: "disusun_oleh",
                apiUrl: "pengguna/api/pengguna/",
                value: "id",
                label: "nama",
            },
            {
                name: "mengetahui",
                apiUrl: "pengguna/api/pengguna/",
                value: "id",
                label: "nama",
            },
        ];
        models.map((item, idx) => {
            this.getMasterList(item.name, item.apiUrl);
        });
    }

    setHariRealisasi(val, idx) {
        const temp = [...this.state.hariAnggota];
        temp[idx]["hari_realisasi"] = val;
        this.setState({
            hariAnggota: temp
        });
    }

    setVal(e, stateName, idx) {
        const tempAgt = [...this.state.anggota];
        tempAgt[idx][stateName] =
            stateName == "is_kartu" ? e.target.checked : e.target.value;
        this.setState({
            anggota: tempAgt,
        });
    }

    addSupervisi() {
        let temp = this.state.supervisi;
        temp = [
            ...temp,
            {
                tipe: 1,
                tanggal_rencana: null,
                tanggal_realisasi: null,
                anggota: false,
            },
        ];
        this.setState({
            supervisi: temp,
        }, () => {

        });
    }

    addRealisasi() {
        let temp = this.state.realisasi;
        temp = [
            ...temp,
            {
                tipe: 2,
                tanggal_rencana: null,
                tanggal_realisasi: null,
                anggota: false,
            },
        ];
        this.setState({
            realisasi: temp,
        }, () => {

        });
    }

    hapusAnggota(idx) {
        const tempAgt = [...this.state.anggota];
        tempAgt.splice(idx, 1);
        this.setState({
            anggota: tempAgt,
        });
    }

    async getData(
        limit = this.state.pagination.pageSize,
        offset = 0,
        search = this.state.searchText
    ) {
        this.setState({ loadingData: true, errTitle: "", errMsg: "" });

        let baseUrl = this.props.configClass.apiUrl.data;
        let url = "";
        if (baseUrl.includes("?")) {
            url =
                baseUrl +
                "&tahun=" +
                this.state.tahunDipilih +
                "&limit=" +
                limit +
                "&offset=" +
                offset +
                "&search=" +
                search;
        } else {
            url =
                baseUrl +
                "?limit=" +
                limit +
                "&tahun=" +
                this.state.tahunDipilih +
                "&offset=" +
                offset +
                "&search=" +
                search;
        }

        if (this.state.filterJenis != "") {
            url += "&jenis=" + this.state.filterJenis;
        }

        this.props
            .ajaxViewHandler("get", url)
            .then((results) => {
                // setTimeout(() => {
                const res = this.props.responseMsg;
                if (this.props.isSuccess) {
                    let pagination = { ...this.state.pagination };
                    pagination.total = res.count;
                    pagination.current = offset + 1;
                    this.setState({
                        loadingData: false,
                        dataSource: res.results,
                        pagination,
                    });
                } else {
                    const errTitle = "error get data code 1";
                    this.setState({
                        loadingData: false,
                        errTitle: errTitle,
                        errMsg: JSON.stringify(res),
                    });
                }
                // }, 500);
            })
            .catch((response) => {
                const errTitle = "error get data code 2";
                this.setState({
                    loadingData: false,
                    errTitle: errTitle,
                    errMsg: JSON.stringify(response),
                });
            });
    }

    handleTableChange = async (pagination, filters, sorter) => {
        const newPagination = { ...this.state.pagination };
        newPagination.pageSize = pagination.pageSize;
        this.setState({
            loadingData: true,
            pagination,
        });
        if (pagination.current == 1) {
            this.getData(pagination.pageSize);
        } else {
            this.getData(
                pagination.pageSize,
                (pagination.current - 1) * pagination.pageSize
            );
        }
    };

    showDeleteConfirm(item) {
        Modal.confirm({
            title: "Konfirmasi Hapus?",
            icon: <ExclamationCircleOutlined />,
            content: "yakin menghapus data?",
            okText: "Ya",
            okType: "danger",
            cancelText: "Tidak",
            onOk: () => this.prosesHapus(item),
            onCancel() {
                //console.log('Cancel');
            },
        });
    }

    resetStatePost() {
        this.setState({
            aksi: "tambah",
            method: "post",
            idLama: "",
            tambah: false,
            editingKey: "",
            open: false,
            anggota: [],
            hariAnggota: [],
            supervisi: [
                {
                    tipe: 1,
                    tanggal_rencana: null,
                    tanggal_realisasi: null,
                    anggota: false,
                },
            ],

            realisasi: [
                {
                    tipe: 2,
                    tanggal_rencana: null,
                    tanggal_realisasi: null,
                    anggota: false,
                },
            ],

            rencana_kunjungan: [],
            catatan: "",
        });
    }

    async doUploadLampiran(datas, id) {
        this.setState({
            loadingData: true,
            errTitle: "",
            errMsg: "",
        });
        // message.loading({ content: 'Memproses...', key: 'toast' });

        this.props
            .ajaxHandler(
                "put",
                this.props.configClass.apiUrl.lampiran + id + "/",
                datas,
                true,
                true
            )
            .then((res) => {
                // setTimeout(() => {
                this.setState({
                    loadingData: false,
                });

                if (res.type == "SUCCESS_HANDLER") {
                    notification.success({
                        message: "sukses",
                        description: "berhasil mengupload berkas.",
                        placement: "bottomRight",
                    });
                    this.getData();
                } else {
                    notification.error({
                        message: "gagal",
                        description: "gagal mengupload berkas.",
                        placement: "bottomRight",
                    });
                    const errTitle = "gagal menyimpan data";
                    // console.log("tes", res);
                    this.setState({
                        errTitle: errTitle,
                        errMsg:
                            res.error.response.data.message != undefined
                                ? JSON.stringify(res.error.response.data.message)
                                : errTitle,
                    });
                }
                // }, 0);
            })
            .catch((response) => {
                notification.error({
                    message: "gagal",
                    description: "gagal mengupload berkas.",
                    placement: "bottomRight",
                });
                this.setState({
                    loadingData: false,
                    errTitle: "gagal menyimpan",
                    errMsg: JSON.stringify(response),
                });
            });
    }

    async prosesReview(status) {
        var params = new FormData();
        params.append("reference", this.state.suratTugas.id);
        params.append("status", status);
        params.append("catatan", this.state.catatan);

        this.setState({
            loadingData: true,
            errTitle: "",
            errMsg: "",
        });
        // message.loading({ content: 'Memproses...', key: 'toast' });

        this.props
            .ajaxHandler(
                "post",
                this.props.configClass.apiUrl.review,
                params,
                true,
                true
            )
            .then((res) => {
                // setTimeout(() => {
                this.setState({
                    loadingData: false,
                });

                if (res.type == "SUCCESS_HANDLER") {
                    notification.success({
                        message: "sukses",
                        description: "berhasil menyimpan review.",
                        placement: "bottomRight",
                    });
                    this.resetStatePost();
                    this.getData();
                } else {
                    notification.error({
                        message: "gagal",
                        description: "gagal menyimpan review.",
                        placement: "bottomRight",
                    });
                    const errTitle = "gagal menyimpan data";
                    // console.log("tes", res);
                    this.setState({
                        errTitle: errTitle,
                        errMsg:
                            res.error.response.data.message != undefined
                                ? JSON.stringify(res.error.response.data.message)
                                : errTitle,
                    });
                }
                // }, 0);
            })
            .catch((response) => {
                notification.error({
                    message: "gagal",
                    description: "gagal menyimpan review.",
                    placement: "bottomRight",
                });
                this.setState({
                    loadingData: false,
                    errTitle: "gagal menyimpan",
                    errMsg: JSON.stringify(response),
                });
            });
    }

    async postData(datas, id) {
        this.setState({
            loadingData: true,
            errTitle: "",
            errMsg: "",
        });
        // message.loading({ content: 'Memproses...', key: 'toast' });

        this.props
            .ajaxHandler(
                "put",
                this.props.configClass.apiUrl.preview + id + "/",
                datas,
                false,
                true
            )
            .then((res) => {
                // setTimeout(() => {
                this.setState({
                    loadingData: false,
                });

                if (res.type == "SUCCESS_HANDLER") {
                    notification.success({
                        message: "sukses",
                        description: "berhasil menyimpan.",
                        placement: "bottomRight",
                    });
                    this.resetStatePost();
                    this.getData();
                } else {
                    notification.error({
                        message: "gagal",
                        description: "gagal menyimpan.",
                        placement: "bottomRight",
                    });
                    const errTitle = "gagal menyimpan data";
                    // console.log("tes", res);
                    this.setState({
                        errTitle: errTitle,
                        errMsg:
                            res.error.response.data.message != undefined
                                ? JSON.stringify(res.error.response.data.message)
                                : errTitle,
                    });
                }
                // }, 0);
            })
            .catch((response) => {
                notification.error({
                    message: "gagal",
                    description: "gagal menyimpan.",
                    placement: "bottomRight",
                });
                this.setState({
                    loadingData: false,
                    errTitle: "gagal menyimpan",
                    errMsg: JSON.stringify(response),
                });
            });
    }

    showActiveConfirm(item) {
        Modal.confirm({
            title: "Konfirmasi Activate?",
            icon: <ExclamationCircleOutlined />,
            content: "yakin mengaktivate data?",
            okText: "Ya",
            okType: "danger",
            cancelText: "Tidak",
            onOk: () => this.prosesActivate(item),
            onCancel() {
                //console.log('Cancel');
            },
        });
    }

    prosesHapus(id) {
        this.setState({
            loadingData: true,
            errMsg: "",
            errTitle: "",
        });

        this.props
            .ajaxHandler("delete", this.props.configClass.apiUrl.hapus + id + "/")
            .then((res) => {
                if (res.type == "SUCCESS_HANDLER") {
                    notification.success({
                        message: "sukses",
                        description: "berhasil menghapus.",
                        placement: "bottomRight",
                    });
                    this.getData();
                } else {
                    this.setState({
                        errTitle: "gagal menghapus",
                        errMsg: JSON.stringify(res.payload.data.message),
                        loadingData: false,
                    });
                }
            })
            .catch((response) => {
                this.setState({
                    loadingData: false,
                    errTitle: "gagal menghapus",
                    errMsg: JSON.stringify(response),
                });
            });
    }

    prosesActivate(id) {
        this.setState({
            loadingData: true,
            errMsg: "",
            errTitle: "",
        });

        this.props
            .ajaxHandler(
                "get",
                this.props.configClass.apiUrl.data + "active/" + id + "/"
            )
            .then((res) => {
                if (res.type == "SUCCESS_HANDLER") {
                    notification.success({
                        message: "sukses",
                        description: "berhasil mengactivate data.",
                        placement: "bottomRight",
                    });
                    this.getData();
                } else {
                    this.setState({
                        errTitle: "gagal mengactivate data",
                        errMsg: JSON.stringify(res.payload.data.message),
                        loadingData: false,
                    });
                }
            })
            .catch((response) => {
                this.setState({
                    loadingData: false,
                    errTitle: "gagal menghapus",
                    errMsg: JSON.stringify(response),
                });
            });
    }

    handleKeyUp = (event) => {
        // Enter
        if (event.keyCode === 13) {
            this.formRef.current.submit();
        }
    };

    handleSubmit = (values) => {
        var params = {};
        for (var key in values) {
            if (values[key] == undefined) {
                params = { ...params, [key]: "" };
            } else {
                // datas.append(key, values[key].toString());
                params = { ...params, [key]: values[key].toString() };
            }
        }
        const rencana_kunjungan = [...this.state.supervisi, ...this.state.realisasi];
        params = { ...params, rencana_kunjungan: rencana_kunjungan };
        params = { ...params, surat_tugas: this.state.suratTugas.surat_tugas };
        params = { ...params, anggota: this.state.hariAnggota };

        // console.log(params)

        this.postData(params, this.state.suratTugas.id);
    };

    render() {
        return (
            <>
                {/*<!-- BEGIN: Content-->*/}
                <Breadcrumb>
                    {this.props.breadCrumb.map((item, idx) => (
                        <Breadcrumb.Item key={"bread" + idx}>{item.label}</Breadcrumb.Item>
                    ))}
                </Breadcrumb>
                <div className="card">
                    <Row className="isi">
                        <Col span={24}>
                            <Card
                                className="konten"
                                title={
                                    <Row>
                                        <Col lg={{ span: 15 }} xs={{ span: 24 }}>
                                            <span className="konten-judul">
                                                {this.props.configClass.name}
                                            </span>
                                        </Col>
                                        <Col
                                            lg={{ span: 9 }}
                                            sm={{ span: 24 }}
                                            xs={{ span: 24 }}
                                            style={{ textAlign: "right" }}
                                        >
                                            <Space>
                                                <Button
                                                    loading={this.state.loadingData}
                                                    type="default"
                                                    size="small"
                                                    onClick={() => this.getData()}
                                                >
                                                    <RedoOutlined />
                                                </Button>
                                                <Link to="file-master/form">
                                                    <Button
                                                        loading={this.state.loadingData}
                                                        size="small"
                                                        type="primary"
                                                    >
                                                        <PlusOutlined /> Upload File
                                                    </Button>
                                                </Link>
                                            </Space>
                                        </Col>
                                    </Row>
                                }
                            >

                                <Form
                                    name="finput"
                                    // onKeyUp={this.handleKeyUp}
                                    onFinish={this.handleSubmit}
                                    autoComplete="off"
                                    ref={this.formRef}
                                >
                                    <Table
                                        bordered
                                        columns={this.state.columns}
                                        dataSource={this.state.dataSource}
                                        loading={this.state.loadingData}
                                        scroll={{ x: "max-content", y: 450 }}
                                        size="small"
                                        pagination={this.state.pagination}
                                        rowKey={this.pk}
                                        onChange={this.handleTableChange}
                                        title={() => (
                                            <Row>
                                                <Col span={24}>
                                                    <Radio.Group
                                                        options={this.state.datajenis_surat}
                                                        onChange={(e) => {
                                                            this.setState(
                                                                {
                                                                    filterJenis: e.target.value,
                                                                },
                                                                () => {
                                                                    this.getData();
                                                                }
                                                            );
                                                        }}
                                                        value={this.state.filterJenis}
                                                        optionType="button"
                                                        buttonStyle="solid"
                                                        style={{ marginBottom: 10 }}
                                                    />
                                                </Col>
                                                <Col lg={8} sm={24} xs={24}>
                                                    <span className="konten-judul">
                                                        Total {this.state.pagination.total} record
                                                    </span>
                                                </Col>
                                                <Col lg={{ span: 8, offset: 8 }} sm={24} xs={24}>
                                                    <Row>
                                                        <Col span={24}>
                                                            <Form.Item>
                                                                <Input.Search
                                                                    size="small"
                                                                    placeholder="ketikkan pencarian"
                                                                    onPressEnter={(e) => {
                                                                        e.preventDefault();
                                                                    }}
                                                                    onSearch={(value) => {
                                                                        this.setState(
                                                                            {
                                                                                searchText: value,
                                                                            },
                                                                            () => {
                                                                                this.getData();
                                                                            }
                                                                        );
                                                                    }}
                                                                    allowClear
                                                                />
                                                            </Form.Item>
                                                        </Col>
                                                    </Row>
                                                </Col>
                                            </Row>
                                        )}
                                    />
                                </Form>
                            </Card>
                        </Col>
                    </Row>
                </div>
            </>
        );
    }
}

const mapStateToProps = function (state) {
    return state.configReducers;
};

export default connect(mapStateToProps, {
    setConfig,
    ajaxHandler,
    ajaxViewHandler,
})(withRouter(DaftarFileMaster));
