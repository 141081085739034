import React, { PureComponent } from "react";
import { Redirect, Link } from "react-router-dom";
import { withRouter } from "react-router";
import {
    Button,
    Row,
    Col,
    Input,
    Layout,
    Form,
    Alert,
    Modal,
    message,
    Switch,
    Tabs,
    notification,
    Spin,
    Pagination,
    Tag,
    Breadcrumb,
    Card,
    Space,
    Table,
    Popconfirm,
    Skeleton,
    Select,
    InputNumber,
    Radio,
    Checkbox,
    DatePicker,
} from "antd";
import {
    TeamOutlined,
    SearchOutlined,
    PlusOutlined,
    FormOutlined,
    CloseSquareOutlined,
    ExclamationCircleOutlined,
    DiffOutlined,
    RedoOutlined,
    EditOutlined,
    DeleteOutlined,
    DownloadOutlined,
    SaveOutlined,
    BookOutlined,
    CopyOutlined
} from "@ant-design/icons";
// import "@ant-design/compatible/assets/index.css";
import Config from "../../Config";
import { Helmet } from "react-helmet";

import { Editor } from "@tinymce/tinymce-react";

import { connect } from "react-redux";
import { setConfig, ajaxHandler, ajaxViewHandler } from "../../store/actions";

import moment from "moment";

import axios from "axios";
import Cookies from "js-cookie";

class FormPengjuanAkhir extends PureComponent {
    config;
    formRef = React.createRef();
    focusComponent = "";
    pk = "";

    constructor(props) {
        super(props);
        this.config = new Config();
        this.state = {
            errTitle: "",
            errMsg: "",
            loadingData: false,

            aksi: "Edit",
            method: "post",
            idLama: "",

            isSuccess: false,
            berkas: null,

            dataLama: null,

            // masalah: [
            //     {
            //         masalah: "",
            //         penyelesaian: "",
            //         halaman: "",
            //         dilakukan_oleh: "",
            //         keterangan: "",
            //     },
            // ],


        };
    }



    componentDidMount() {
        // console.log('didmount', this.props)
        this.setState({
            aksi: this.props.match.params.id != undefined ? "edit" : "tambah"
        })
        if (this.props.match.params.id != undefined) {
            this.preview();
        }
    }

    async preview() {
        this.setState({ loadingData: true, errTitle: "", errMsg: "" });

        let url = this.props.configClass.apiUrl.data + this.props.match.params.id;

        this.props
            .ajaxViewHandler("get", url)
            .then((results) => {
                // setTimeout(() => {
                const data = this.props.responseMsg;
                if (this.props.isSuccess) {
                    this.setState({
                        dataLama: data,
                    })
                    // this.formRef.current.setFieldsValue(data);
                    for (var key in data) {
                        const dateRegex = /^[0-9]{4}\-[0-9]{2}\-[0-9]{2}$/;
                        const dateTimeRegex =
                            /^[0-9]{4}\-[0-9]{2}\-[0-9]{2} [0-9]{2}\:[0-9]{2}\:[0-9]{2}$/;
                        if (dateRegex.test(data[key])) {
                            //jika format tanggal
                            this.formRef.current.setFieldsValue({
                                [key + "_picker"]: moment(new Date(data[key]), "DD-MM-YYYY"),
                                [key]: moment(new Date(data[key]), "DD-MM-YYYY"),
                            });
                        } else if (dateTimeRegex.test(data[key])) {
                            //jika format tanggal dan jam
                            this.formRef.current.setFieldsValue({
                                [key + "_picker"]: moment(
                                    new Date(data[key]),
                                    "DD-MM-YYYY HH:mm:ss"
                                ),
                                [key]: moment(new Date(data[key]), "DD-MM-YYYY HH:mm:ss"),
                            });
                        } else {
                            if (key == "body") {
                                this.setState({
                                    artikelString: data[key],
                                });
                                this.formRef.current.setFieldsValue({
                                    content_editor: data[key],
                                    body_editor: data[key],
                                });
                            } else if (key == "tag") {
                                let temp = [];
                                data[key].map((item) => {
                                    temp = [
                                        ...temp,
                                        {
                                            value: item.id,
                                            label: item.nama,
                                            key: item.id,
                                        },
                                    ];
                                });
                                this.formRef.current.setFieldsValue({
                                    tags: temp,
                                });
                            } else if (key == "image" || key == "dokumen") {
                                // let temp = [];
                                // let tempGb = [];
                                // data[key].map((item) => {
                                //   temp = [
                                //     ...temp,
                                //     {
                                //       id: item.id,
                                //       name: item.name,
                                //     },
                                //   ];
                                //   tempGb = [
                                //     ...tempGb,
                                //     {
                                //       id: item.id,
                                //       name: item.name,
                                //       path: item.path,
                                //     },
                                //   ];
                                // });
                                // this.setState({
                                //   gbr_post: temp,
                                //   gambar_edit: tempGb,
                                // });
                            } else {
                                this.formRef.current.setFieldsValue({
                                    [key]: data[key],
                                });
                            }
                        }

                        // if (key == "icon") {
                        //   this.setState({
                        //     preview_gambar: data[key],
                        //   });
                        // }
                    }
                    message.destroy();
                    this.setState(
                        {
                            aksi: "edit",
                            method: "put",
                            idLama: data.id + "/",
                            loadingData: false,
                        },
                        () => {
                            if (this.focusComponent != null && this.focusComponent != "") {
                                document.getElementById(this.focusComponent).focus();
                            }
                        }
                    );
                } else {
                    const errTitle = "error get data code 1";
                    this.setState({
                        loadingData: false,
                        errTitle: errTitle,
                        errMsg: JSON.stringify(data),
                    });
                }
                // }, 500);
            })
            .catch((response) => {
                const errTitle = "error get data code 2";
                this.setState({
                    loadingData: false,
                    errTitle: errTitle,
                    errMsg: JSON.stringify(response),
                });
            });
    }

    async postData(datas) {
        this.setState({
            loadingData: true,
            errTitle: "",
            errMsg: "",
        });
        // message.loading({ content: 'Memproses...', key: 'toast' });

        this.props
            .ajaxHandler(
                this.state.method,
                this.props.configClass.apiUrl.preview + this.state.idLama,
                datas,
                true,
                true
            )
            .then((res) => {
                // setTimeout(() => {
                this.setState({
                    loadingData: false,
                });

                if (res.type == "SUCCESS_HANDLER") {
                    notification.success({
                        message: "sukses",
                        description: "berhasil menyimpan.",
                        placement: "bottomRight",
                    });
                    this.setState({
                        isSuccess: true
                    })
                } else {
                    notification.error({
                        message: "gagal",
                        description: "gagal menyimpan.",
                        placement: "bottomRight",
                    });
                    const errTitle = "gagal menyimpan data";
                    // console.log("tes", res);
                    this.setState({
                        errTitle: errTitle,
                        errMsg:
                            res.error.response.data.message != undefined
                                ? JSON.stringify(res.error.response.data.message)
                                : errTitle,
                    });
                }
                // }, 0);
            })
            .catch((response) => {
                notification.error({
                    message: "gagal",
                    description: "gagal menyimpan.",
                    placement: "bottomRight",
                });
                this.setState({
                    loadingData: false,
                    errTitle: "gagal menyimpan",
                    errMsg: JSON.stringify(response),
                });
            });
    }

    handleSubmit = (values) => {
        var params = new FormData();
        for (var key in values) {
            if (values[key] == undefined) {
                params.append([key], "");
            } else {
                params.append([key], values[key].toString());
            }
        }
        if (this.state.berkas != null) {
            params.append("lampiran", this.state.berkas, this.state.berkas.name);
        }
        this.postData(params);
    };

    setFile(e) {
        this.setState(
            {
                berkas: e.target.files[0],
            },
            () => {
                // console.log(this.state[name+"_file"])
            }
        );
    }

    render() {
        if (this.state.isSuccess) {
            return <Redirect to="/admin/pelaporan/daftar-pengujian-akhir" />
        }
        return (
            <>
                {/*<!-- BEGIN: Content-->*/}
                <Breadcrumb>
                    {this.props.breadCrumb.map((item, idx) => (
                        <Breadcrumb.Item key={"bread" + idx}>{item.label}</Breadcrumb.Item>
                    ))}
                </Breadcrumb>
                <div className="card">
                    <Row className="isi">
                        <Col span={24}>
                            <Card
                                className="konten"
                                title={
                                    <Row>
                                        <Col lg={{ span: 20 }} xs={{ span: 24 }}>
                                            <span className="konten-judul">
                                                {this.state.aksi + " " + this.props.configClass.name}
                                            </span>
                                        </Col>
                                    </Row>
                                }
                            >

                                <Spin
                                    spinning={this.state.loadingData}
                                >
                                    <Form
                                        name="finput"
                                        // onKeyUp={this.handleKeyUp}
                                        onFinish={this.handleSubmit}
                                        autoComplete="off"
                                        ref={this.formRef}
                                        layout="vertical"
                                        disabled={this.state.aksi.toLowerCase() == "view" ? true : false}

                                    >
                                        <Row>
                                            <Col span={24}>
                                                <Form.Item
                                                    label="NAMA DOKUMEN"
                                                    name={"nama"}
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: " harus diisi",
                                                        },
                                                    ]}
                                                >
                                                    <Input />
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col span={24}>
                                                <Form.Item
                                                    label="BERKAS"
                                                    name={"berkas_picker"}
                                                    rules={[
                                                        {
                                                            required: this.state.aksi == "tambah" ? true : false,
                                                            message: this.state.aksi == "tambah" ? " harus diisi" : "",
                                                        },
                                                    ]}
                                                >
                                                    <Input
                                                        type="file"
                                                        onChange={(e) => this.setFile(e)}
                                                    />
                                                </Form.Item>
                                                {this.state.aksi == "edit" && this.state.dataLama != null && this.state.dataLama.lampiran != null && this.state.dataLama.lampiran != "" &&
                                                    <a href={this.state.dataLama.lampiran} target="_blank">
                                                        <DownloadOutlined /> Download Lampiran
                                                    </a>
                                                }
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col span={24}>
                                                <Form.Item
                                                    label="NOMOR URUT DOKUMEN"
                                                    name={"nomor_urut"}
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: " harus diisi",
                                                        },
                                                    ]}
                                                >
                                                    <InputNumber
                                                        style={{
                                                            width: "100%"
                                                        }}
                                                    />
                                                </Form.Item>
                                            </Col>
                                        </Row>

                                        <Form.Item
                                            wrapperCol={{
                                                offset: 0,
                                                span: 24,
                                            }}
                                        >
                                            <Space>
                                                <Button type="primary" htmlType="submit">
                                                    <SaveOutlined />
                                                    Simpan
                                                </Button>
                                                <Link to="/admin/pelaporan/daftar-pengujian-akhir">
                                                    <Button
                                                        type="primary"
                                                        danger
                                                        htmlType="button"
                                                    >
                                                        <CloseSquareOutlined />
                                                        Batal
                                                    </Button>
                                                </Link>
                                            </Space>
                                        </Form.Item>

                                    </Form>
                                </Spin>
                            </Card>
                        </Col>
                    </Row>
                </div>
            </>
        );
    }
}

const mapStateToProps = function (state) {
    return state.configReducers;
};

export default connect(mapStateToProps, {
    setConfig,
    ajaxHandler,
    ajaxViewHandler,
})(withRouter(FormPengjuanAkhir));
